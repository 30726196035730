import React from "react"

import Header from "./home/_header"

function SignUp(props) {
  const pathname = props.location.pathname
  const [hasSubmitted, setSubmitted] = React.useState(false)
  const [isLoading, setLoading] = React.useState(false)
  const [isError, setError] = React.useState(false)

  async function handleFormSubmit(event) {
    setLoading(true)
    event.preventDefault()

    const formData = new FormData(event.target)
    //   const files = event.target.files
    //   formData.append("files", files)

    try {
      await fetch("https://sendgrid-attachment.onrender.com/upload", {
        method: "POST",
        headers: {
          Accept: "*/*",
        },
        body: formData,
      }).then(res => res.json())

      setSubmitted(true)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setError(true)
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header pathname={pathname} />

      {/*  Page content */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Application</h1>
              </div>
              {isError && (
                <div className="max-w-sm mx-auto">
                  <div className="flex flex-wrap justify-center mb-4">
                    <p className="text-red-500 text-lg text-center">
                      Something went wrong. Please try again.
                    </p>
                  </div>
                </div>
              )}

              {/* Form */}
              {!hasSubmitted ? (
                <div className="max-w-sm mx-auto">
                  <form onSubmit={handleFormSubmit}>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-800 text-sm font-medium mb-1"
                          htmlFor="name"
                        >
                          Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-input w-full text-gray-800"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          className="block text-gray-800 text-sm font-medium mb-1"
                          htmlFor="email"
                        >
                          Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="form-input w-full text-gray-800"
                          placeholder="Enter your email address"
                          required
                        />
                      </div>
                    </div>
                    <p className="text-sm text-gray-600 text-left my-4">
                      Instructions: Please download Form A{" "}
                      <a
                        href="/forms/form-a.xlsx"
                        className="text-blue-600 hover:underline"
                        target="_blank"
                      >
                        here
                      </a>
                      .<br />
                      Upon completion, please upload form below for submission.
                    </p>
                    <div className="flex flex-wrap -mx-3 mb-4">
                      <div className="w-full px-3">
                        <label
                          htmlFor="formFileMultiple"
                          className="block text-gray-800 text-sm font-medium mb-1"
                        >
                          Application form (Form A){" "}
                          <span className="text-red-600">*</span>
                        </label>
                        <input
                          className="form-control
                            block
                            w-full
                            px-3
                            py-3
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          type="file"
                          name="file"
                          id="formFileMultiple"
                          multiple
                          required
                        />
                      </div>
                    </div>
                    {/* <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <label className="flex items-center">
                          <input type="checkbox" className="form-checkbox" />
                          <span className="text-gray-600 ml-2">
                            Keep me signed in
                          </span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                    <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <button
                          className={`btn text-white bg-blue-600 hover:bg-blue-700 w-full ${
                            isLoading && `opacity-50 cursor-not-allowed`
                          }`}
                        >
                          {isLoading ? (
                            <>
                              Processing...
                              <svg className="spinner mx-4" viewBox="0 0 50 50">
                                <circle
                                  className="path"
                                  cx="25"
                                  cy="25"
                                  r="20"
                                  fill="none"
                                  stroke-width="5"
                                />
                              </svg>
                            </>
                          ) : (
                            <>Submit</>
                          )}
                        </button>
                        {isLoading && (
                          <div className="text-sm text-gray-600 mt-2">
                            This may take awhile. Please don't close the window
                            while it is still being processed.
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className="flex flex-wrap -mx-3 mt-6">
                      <div className="w-full px-3">
                        <p className="text-gray-600 text-sm">
                          Have not downloaded the application form?{" "}
                          <a
                            href="/forms/form-a.xlsx"
                            className="text-blue-600 hover:underline"
                            target="_blank"
                          >
                            Get it here
                          </a>
                          .
                        </p>
                      </div>
                    </div> */}
                  </form>
                </div>
              ) : (
                <div className="max-w-sm mx-auto">
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <p className="text-gray-600 text-lg">
                      Thank you for your application. We shall contact you by
                      email if you are shortlisted for further consideration.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

export default SignUp
